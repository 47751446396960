import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const CustomerResults = ({ gridItems }) => {
  const [on, toggle] = useState(false);
  const fadeIn = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.slow
  })
  return (
    <div className="columns is-multiline" 
        style={{
          display: `flex`
    }}>
      <Waypoint 
        bottomOffset="10%"
        onEnter={() => { 
          if (!on) toggle(true);
        }}        
      />
      {gridItems.map( (item, i) => (
        <div key={i} className="column is-12 customer-results-section">
          <section >         
              <div className="customer-result has-text-centered" >
                <div
                  style={{
                    width: '60px',
                    display: 'inline-block',
                  }}
                >
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
                <div className="customer-result__text"><animated.p className="item-percent" style={fadeIn}>{item.amount}</animated.p>
                <p className="item-text">{item.text}</p></div>
              </div>
          </section>
        </div>
      ))}
    </div>
  )
}

CustomerResults.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default CustomerResults
