import React, { useState } from "react";
import { animated } from "react-spring";
import { Waypoint } from 'react-waypoint'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

/* eslint-disable */

const ButtonToggle = ({gridItems}) => {
  const [isHere, setHere] = useState(false);
  const {image1, image2} = gridItems

  return (

    <div className="btn-toggle-container">
      <Waypoint
        topOffset="30%"
        onEnter={() => {
          if (isHere) setHere(false);
        }}
      />
      <div className="btn-toggle-wrapper" onClick={() => setHere(!isHere)}>
        <animated.div className={`toggle-image-a ${isHere ? "is-active" : ""}`}>
          <PreviewCompatibleImage imageInfo={image1} />
        </animated.div>
        <div className={`toggle-image-b ${!isHere ? "is-active" : ""}`}>
          <PreviewCompatibleImage imageInfo={image2} />
        </div>
      </div>
      <Waypoint
        bottomOffset="30%"
        onEnter={() => {
          if (!isHere) setHere(true);
        }}
      />
    </div>
  )
}

export default ButtonToggle;
