import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import TwoColumn from '../components/TwoColumn'
import SubNavbar from '../components/SubNavbar'
import CustomerResults from '../components/CustomerResults'
import ProjectOverview from '../components/ProjectOverview'
import ButtonToggle from '../components/ButtonToggle'

export const CaseStudyPageTemplate = ({
  sofware,
  breadcrumb,
  title,
  metatitle,
  metadescription,
  heading,
  customerresults,   
  twocolumn,
  projectoverview,
  toggleimage,
  reasonsheader,
  reasonstext,
  reasons,
  helmet,
}) => (
  <div className="casestudy-pg ">    
    {helmet || ''}     
      
      <div className="container">        
      
        <div className="hero-text"
          style={{
            display: 'flex',
            maxWidth: '500px',
            lineHeight: '1',
            alignItems: 'left',
            flexDirection: 'column'
          }}> 
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><span>Use Cases</span></li>
              <li><span>{breadcrumb}</span></li>
            </ul>
          </nav> 
          <h1
            className="has-text-weight-bold"
            style={{            
              lineHeight: '1.2',
              padding: '0.25em',
            }}            
          >   {heading}
          </h1>                    
        </div>
      
      </div> 
    
    <section className="section">
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">                     
                <div className="columns intro-customer-results">                  
                  <div className="column is-12">                    
                    <CustomerResults gridItems={customerresults.results} />
                  </div>
                </div> 
                <div className="columns casestudy-section">
                  <div className="column is-10 is-offset-1 two-column-reverse">
                    <TwoColumn gridItems={twocolumn} />
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{ padding: '0' }}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">                                
              
                <div className="columns casestudy-section project-overview ">
                  <div className="column is-10 is-offset-1 two-column-reverse">
                    <ProjectOverview gridItems={projectoverview} />
                  </div>
                </div>
                <div className="columns casestudy-section project-overview-three">
                  
                    <div className="column is-10 two-column">          
                      <section className="section columns">            
                        <div className="column is-6 two-column--text">
                          <h2>{toggleimage.header}</h2>
                          <div className="two-column--text-body"
                            dangerouslySetInnerHTML={{
                              __html: toggleimage.text,
                            }}
                          />                                        
                        </div>                      
                        <div className="has-text-centered column is-6">
                          <ButtonToggle gridItems={toggleimage}/>
                        </div>                        
                      </section>                    
                    </div>                                      
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="section" style={{ padding: '0' }}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">                
                <div className="columns reasons-section">
                  <div className="column is-12">                    
                    <div className="reasons-section__text">
                      <h2>{reasonsheader}</h2>                  
                      <p>{reasonstext}</p>
                    </div>
                    <TwoColumn gridItems={reasons} />
                  </div>                  
                </div>                                                        
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

CaseStudyPageTemplate.propTypes = {  
  software: PropTypes.string,
  breadcrumb: PropTypes.string,
  title: PropTypes.string,
  metatitle: PropTypes.string,
  metadescription: PropTypes.string,
  heading: PropTypes.string,  
  customerresults: PropTypes.shape({
    results: PropTypes.array,
  }),
  twocolumn: PropTypes.array,
  projectoverview: PropTypes.array,
  toggleimage: PropTypes.shape({    
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),    
    header: PropTypes.string,   
    text: PropTypes.string,  
  }),
  reasonsheader: PropTypes.string,  
  reasonstext: PropTypes.string,
  reasons: PropTypes.array,  
  helmet: PropTypes.object,
}

const CaseStudyPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <div className="subnavtop">
      <SubNavbar
        software={post.frontmatter.software}
      />
      <Layout>
        
        <CaseStudyPageTemplate        
          breadcrumb={post.frontmatter.breadcrumb}      
          title={post.frontmatter.title}      
          metatitle={post.frontmatter.metatitle}
          metadescription={post.frontmatter.metadescription}
          heading={post.frontmatter.heading}          
          customerresults={post.frontmatter.customerresults}  
          twocolumn={post.frontmatter.twocolumn}                          
          projectoverview={post.frontmatter.projectoverview}                          
          toggleimage={post.frontmatter.toggleimage}      
          reasonsheader={post.frontmatter.reasonsheader}
          reasonstext={post.frontmatter.reasonstext}
          reasons={post.frontmatter.reasons}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${post.frontmatter.metatitle}`}</title> 
              <meta name="description" content={`${post.frontmatter.metadescription}`} />          
            </Helmet>
          }      
        />
      </Layout>
    </div>
  )
}

CaseStudyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CaseStudyPage

export const pageQuery = graphql`
  query CaseStudyPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "case-study" } }) {
      id
      frontmatter {
        software
        breadcrumb
        title
        metatitle
        metadescription
        heading 
        customerresults {
          results {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            amount
            text
          }
        }         
        twocolumn {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text          
        }        
        projectoverview {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text  
          projectdescription        
        }
        toggleimage {
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt  
          }
          image2 {
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt  
          }
          header
          text
        }
        reasonsheader
        reasonstext
        reasons {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 100, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text          
        }         
               
      }
    }
  }
`
